<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input v-model="query.code" placeholder="服务单号" class="handle-input mr10" clearable></el-input>
				<el-input v-model="query.medic_name" placeholder="专家" class="handle-input mr10" clearable></el-input>
				<el-input v-model="query.info_name" placeholder="就诊人" class="handle-input mr10" clearable></el-input>
				<el-select v-model="query.status" placeholder="缴费状态" class="handle-select mr10" clearable>
					<el-option key="1" label="待缴费" value="0"></el-option>
					<el-option key="2" label="部分缴费" value="1"></el-option>
					<el-option key="3" label="缴费完成" value="2"></el-option>
					<el-option key="4" label="已关闭" value="3"></el-option>
				</el-select>
				<el-select v-model="query.assess" placeholder="疗效评估" class="handle-select mr10" clearable>
					<el-option key="1" label="治愈" value="治愈"></el-option>
					<el-option key="2" label="好转" value="好转"></el-option>
					<el-option key="3" label="无变化" value="无变化"></el-option>
					<el-option key="4" label="加重" value="加重"></el-option>
					<el-option key="5" label="失败" value="失败"></el-option>
				</el-select>
				<div style="margin: 10px 0;">
					<label class="el-form-item__label">服务日期：
						<el-date-picker v-model="query.serve_time" type="date" placeholder="选择日期"
							value-format="YYYY-MM-DD" @change="handleDate"></el-date-picker>
					</label>
					<label class="el-form-item__label">创建日期：
						<el-date-picker @change="handleDate2" v-model="date" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</label>
				</div>
				<el-button type="primary" icon="el-icon-search" class="ml10" @click="handleSearch">搜索</el-button>
				<el-button type="info" icon="el-icon-copy-document" class="ml10" @click="handleReset">重置</el-button>
			</div>
			<el-table :data="tableData" class="table" ref="multipleTable" header-cell-class-name="table-header"
				@row-click="handleInfo">
				<el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
				<el-table-column align="center" prop="code" label="服务单号"></el-table-column>
				<el-table-column align="center" prop="name" label="就诊人">
					<template #default="scope">
						<div class="user_name" @click.stop="nameClick(scope.row.info_id)">{{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="other" label="已做疗程/总次数"></el-table-column>
				<el-table-column align="center" prop="medic_name" label="专家"></el-table-column>
				<el-table-column align="center" label="缴费状态">
					<template #default="scope">
						<el-tag
							:type=" scope.row.status === '缴费完成' ? 'success' : scope.row.status === '待缴费' ? 'danger' : 'info' ">
							{{ scope.row.status }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="create_time" width="250" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="220">
					<template #default="scope">
						<el-button type="text" icon="el-icon-info" @click.stop="handleInfo(scope.row)">详情</el-button>
						<el-button v-if="scope.row.status === '待缴费'&&getUserId==scope.row.medic_id" type="text"
							icon="el-icon-edit" @click.stop="handleEdit(scope.row)">编辑</el-button>
						<el-button v-if="scope.row.status === '待缴费'&&getUserId==scope.row.medic_id" type="text"
							icon="el-icon-remove" @click.stop="handleDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next, sizes" :current-page="query.page"
					:page-size="query.limt" :page-sizes="[10, 20, 50]" :total="pageTotal"
					@current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		diagnosisList,
		userInfo,
		delDiagnosis
	} from "../../api/index";
	export default {
		name: "curativeList",
		data() {
			return {
				query: {
					page: 1,
					limt: 10,
					code: "",
					info_name: "", //就诊人名字
					medic_name: "", //专家名
					status: "",
					assess: "",
					create_begin: "",
					create_end: "",
					serve_time: "",
					medic_id: ''
				},
				date: '',
				pageTotal: 0, //总条数
				tableData: [],
			};
		},
		created() {
			let query = this.$route.query
			let types = localStorage.getItem("types");

			if (query && query.begin) {
				this.query.create_begin = query.begin
				this.query.create_end = query.end
			}
			if (query && query.serve_begin) {
				this.query.serve_begin = query.serve_begin
				this.query.serve_end = query.serve_end
			}
			if (query && query.id) {
				this.query.medic_id = query.id
			}
			if (query && query.finish) {
				this.query.finish = query.finish
			}
			if (query && query.today_time) {
				this.query.serve_time = query.today_time
			}
			if (query && query.status) {
				this.query.status = query.status
			}

			if (types == '专家') {
				userInfo().then((res) => {
					this.query.medic_id = res.id
					this.getData();
				})
			} else {
				this.getData();
			}
		},
		computed: {
			getUserId() { //获取管理员id
				return this.$store.state.userInfo.id;
			}
		},
		methods: {
			getData() {
				diagnosisList(this.query).then(res => {
					this.tableData = res.list;
					this.pageTotal = res.total;
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.page = 1
				this.getData();
			},
			// 重置
			handleReset() {
				this.date = ''
				this.query = {
					page: 1,
					limt: 10,
					code: "",
					info_name: "", //就诊人名字
					medic_name: "", //专家名
					status: "",
					assess: "",
					create_begin: "",
					create_end: "",
					serve_time: "",
					medic_id: this.query.medic_id
				}
								console.log(this.query);
				this.getData();
			},

			// 点击名字跳转
			nameClick(id) {
				this.$router.push({
					path: '/memberInfo',
					query: {
						id: id
					}
				})
			},

			// 编辑操作
			handleEdit(row) {
				console.log(row.id);
				this.$router.push({
					path: '/newEdit',
					query: {
						id: row.id,
						types: 2
					}
				})
			},
			
			// 删除操作
			handleDel(row) {
				delDiagnosis({id:row.id}).then(() => {
					this.$message.success("删除成功");
					setTimeout(function(){
						location. reload()
					},1000)
					
				});
			},

			// 详情操作
			handleInfo(row) {
				this.$router.push({
					path: '/curativeInfo',
					query: {
						id: row.id
					}
				})
			},

			// 时间选择
			handleDate() {
				if (this.query.serve_time) {
					const d = this.query.serve_time.getFullYear() + '-' + (this.query.serve_time.getMonth() + 1) + '-' +
						this.query.serve_time.getDate()
					this.query.serve_time = d
				} else {
					this.query.serve_time = ''
				}
			},
			handleDate2() {
				if (this.date) {
					const d = new Date(this.date[0])
					const d2 = new Date(this.date[1])
					this.query.create_begin = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
					this.query.create_end = d2.getFullYear() + '-' + (d2.getMonth() + 1) + '-' + d2.getDate()
				} else {
					this.query.create_begin = ''
					this.query.create_end = ''
				}
				// console.log(this.query.create_begin)    
				// console.log(this.query.create_end)    
			},

			// 分页导航
			handlePageChange(val) {
				this.query.page = val
				this.getData();
			},
			handleSizeChange(val) {
				this.query.page = 1
				this.query.limt = val
				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.ml10 {
		margin-left: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}

	.user_name {
		font-size: 14px;
		color: #66b1ff;
		cursor: pointer;
		font-weight: 500;
		display: inline-block;
		border-bottom: 1px solid transparent;
	}

	.user_name:hover {
		border-bottom: 1px solid #66b1ff;
		/* text-decoration:underline; */
	}
</style>
